import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ReactPlayer from 'react-player'

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

import { Helmet } from 'react-helmet';

const SchemaOrg = () => {
	const schema = [{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "The Websuasion Group LLC",
  "url": "https://websuasion.com/",
  "logo": "https://websuasion.com/images/websuasion-full-logo.png",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+1-404-418-8909",
    "contactType": "Customer Service",
    "areaServed": "US",
    "availableLanguage": "English",
    "contactOption": "TollFree",
    "email": "sales@websuasion.com"
  },
  "sameAs": [
    "https://www.linkedin.com/company/websuasion",
    "https://www.youtube.com/@websuasion",
    "https://www.instagram.com/websuasion/",
    "https://www.threads.net/@websuasion",
    "https://twitter.com/websuasion",
    "https://www.tiktok.com/@leadarchitect",
    "https://www.facebook.com/websuasion"
  ],
  "description": "Websuasion is a fractional, white-label marketing agency offering data-driven strategies, advanced technologies, and a team of experienced specialists to deliver comprehensive digital marketing services.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "PO BOX 142395",
    "addressLocality": "Fayetteville",
    "addressRegion": "GA",
    "postalCode": "30214-9998",
    "addressCountry": "US"
  },
  "founder": {
    "@type": "Person",
    "name": "J. Ryan Williams"
  },
  "employee": [
    {
      "@type": "Person",
      "name": "J. Ryan Williams",
      "jobTitle": "Fractional Chief Marketing Officer",
      "worksFor": {
        "@type": "Organization",
        "name": "The Websuasion Group LLC"
      }
    }
  ],
  "makesOffer": [
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Marketing Technology",
        "serviceType": "Marketing Technology",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Buford",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buford",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Atlanta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Atlanta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Roswell",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Roswell",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Vinings",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vinings",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Marietta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Marietta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Woodstock",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Woodstock",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Alpharetta",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Alpharetta",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Brookhaven",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Brookhaven",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Fayetteville",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Fayetteville",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Senoia",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Senoia",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Decatur",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Decatur",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Sandy Springs",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sandy Springs",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree City",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree City",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Buckhead",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Buckhead",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Peachtree Corners",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Peachtree Corners",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
          {
            "@type": "Place",
            "name": "Candler Park",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Candler Park",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          }
        ]
      }
    },
  ]
},
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "What is a MQL (Marketing Qualified Lead) and a SQL (Sales Qualified Lead)?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "An MQL has shown interest in your products or services through your marketing efforts, but they have yet to talk to your sales team. An SQL has been vetted by a sales development representative (SDR) and is ready to speak to a sales representative."
      }
    },
    {
      "@type": "Question",
      "name": "What is the difference between a MQL and a prospect?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A prospect can be any potential customer. A marketing-qualified lead is a prospect who has demonstrated specific - and preferably trackable -interest in your product or service by engaging with your marketing content."
      }
    },
    {
      "@type": "Question",
      "name": "What are examples of marketing-qualified leads?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Usually, a marketing-qualified lead has clicked on one of your calls to action. Examples include: Visiting your product or service page multiple times; Downloading trial software or ebooks; Using demos; Filling out forms; Subscribing to newsletters; Adding items to carts; and Requesting more information. Their actions indicate a strong interest in your product or service."
      }
    },
    {
      "@type": "Question",
      "name": "How do you get marketing-qualified leads?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To get MQLs, start by creating valuable content optimized for conversion. Include lead magnets in your calls to action and regularly measure your analytics, making adjustments when those calls to action are underperforming."
      }
    },
    {
      "@type": "Question",
      "name": "What determines a qualified lead?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can consider a lead qualified when they check off the requirements your marketing and sales teams have identified as common traits for a prospect ready to buy."
      }
    },
    {
      "@type": "Question",
      "name": "What are the five requirements for a lead to be considered qualified?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "1. Interest: Prospect shows genuine enthusiasm for your offering. 2. Need: Clear alignment between the prospect's requirements and your solution. 3. Budget: Prospect has the financial capacity to make the purchase. 4. Urgency: Prospect is prepared to take action immediately. 5. Authority: Prospect possesses decision-making power or significant influence in the buying process."
      }
    },
    {
      "@type": "Question",
      "name": "How should I track my marketing-qualified leads?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Tracking MQLs can be challenging. Monitor your website traffic, comparing each month to previous years to adjust your expectations for seasonality. Track the website visitors who convert to leads and your leads who convert to customers. Measure engagement metrics on social sites and correlate them with referral traffic."
      }
    },
    {
      "@type": "Question",
      "name": "How should I qualify my leads?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Create a system to score each new lead based on traits that align with your ideal customer profile. You can also analyze their engagement with your content to determine where they are in the customer's journey. This research can help you to follow up with the right messaging."
      }
    }
  ]
},
{
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "What is a Marketing Qualified Lead?",
  "author": {
    "@type": "Person",
    "name": "J. Ryan Williams"
  },
  "datePublished": "2024-08-16",
  "publisher": {
    "@type": "Organization",
    "name": "The Websuasion Group LLC", 
    "url": "https://websuasion.com",
    "logo": {
      "@type": "ImageObject",
      "url": "https://websuasion.com/images/websuasion-full-logo.png"
    }
  },
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://websuasion.com/marketing-technology/marketing-qualified-lead-defined/"
  },
  "image": "https://websuasion.com/images/marketing-qualified-lead-defined.webp",
  "articleSection": "What is a Marketing Qualified Lead?",
  "description": "Learn about the marketing qualified lead and how to leverage marketing technology for optimal MQL tracking and conversion.",
  "keywords": ["Marketing Qualified Lead", "MQL", "Marketing Technology", "Martech", "Marketing Automation", "Marketing Technology Stack"],
  "hasPart": [
    {
      "@type": "VideoObject",
      "name": "Marketing Technology: Building Customized Automated Lead Funnels - Websuasion",
      "description": "Marketing technology (MarTech) is software that makes planning, executing, and measuring marketing campaigns on different channels easier. MarTech helps marketers reach and keep customers in a multi-touchpoint, omnichannel environment.",
      "thumbnailUrl": "https://i.ytimg.com/vi/qAhiqRUOX0w/maxresdefault.jpg",
      "uploadDate": "2024-05-16T14:51:43-07:00",
      "duration": "PT14M10S",
      "contentUrl": "https://www.youtube.com/watch?v=qAhiqRUOX0w",
      "embedUrl": "https://www.youtube.com/embed/qAhiqRUOX0w"
    },
    {
      "@type": "VideoObject",
      "name": "Contacting Websuasion for an Introductory Meeting",
      "description": "At Websuasion, our motto is: Data. Tech. Team. Do the research. Harness the technology. But ultimately, success comes down to human decisions. We hope to get to know you and your business soon.",
      "thumbnailUrl": "https://i.ytimg.com/vi/-YTR647HTBM/maxresdefault.jpg",
      "uploadDate": "2024-05-16T10:07:01-07:00",
      "duration": "PT1M9S",
      "contentUrl": "https://www.youtube.com/watch?v=-YTR647HTBM",
      "embedUrl": "https://www.youtube.com/embed/-YTR647HTBM"
    }
  ]
}];
	
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

const IndexPage = () => {
  return (
    <PageLayout>
			<SchemaOrg />
      <Seo
        title="What is a Marketing Qualified Lead? | Marketing Technology"
        ogDescription="Learn about the marketing qualified lead and how to leverage marketing technology for optimal MQL tracking and conversion."
        image="/images/marketing-qualified-lead-defined.webp"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>What is a Marketing Qualified Lead?</h1>
					
						<p>
							A Marketing Qualified Lead (MQL) has indicated their interest in your brand's offerings through specific actions, such as downloading content, attending <a href="/atlanta-video-production/webinar-production/">webinars</a>, or engaging with marketing materials. MQLs are more likely to become customers than general leads but are not ready for direct sales engagement.
						</p>
					
						<p>
							Understanding and effectively managing your leads is crucial for success. Expert integration of <a href="/marketing-technology/">marketing technology</a> can help you better identify and score your Marketing Qualified Leads.
						</p>

		        <FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
		      <div className='player-wrapper'>
		        <ReactPlayer
		          className='react-player'
		          url='https://www.youtube.com/watch?v=qAhiqRUOX0w'
		          width='100%'
		          height='100%'
		        />
		      </div>
        }
      />
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/marketing-qualified-lead-defined.webp" className="rounded-image" alt="What is a Marketing Qualified Lead (MQL)? - Websuasion" />
	          </div>
	        }
	        textRight={
			      <div>
							<h2>Characteristics of Marketing Qualified Leads</h2>

							<p>
								Unlike passive leads, MQLs are actively engaged with your brand and have intentionally interacted with your <a href="/white-label-marketing-services/">marketing efforts</a> in ways that indicate a higher likelihood of becoming customers. By effectively identifying and nurturing MQLs, you can streamline the sales process, improve conversion rates, and drive revenue growth.
							</p>

							<p>
								Actions and behaviors of MQLs include:
							</p>
								
							<ul>
								<li>Downloading trial software or free ebooks</li>
								<li>Using software demos</li>
								<li>Filling out online forms</li>
								<li>Submitting email addresses for newsletters or mailing lists</li>
								<li>Favoriting items or adding them to wishlists</li>
								<li>Adding items to shopping carts</li>
								<li>Repeated site visits or spending significant time on your site</li>
								<li>Clicking on ads to find your site</li>
								<li>Contacting you to request more information</li>
							</ul>
			      </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								Marketing Qualified Leads (MQLs) bridge the gap between initial interest and sales-readiness. They sit between general leads and Sales Qualified Leads (SQLs) in the sales funnel. The goal in the MQL stage is to capture the prospect's information and accurately score it in your CRM system so the sales team can take over at the appropriate stage of the customer journey. Integrating your marketing calls to action to trigger the proper actions in your lead generation system is essential. 
							</p>
						
			        <FormWrapper
			          modal
			          buttonProps={{
			            content: "Schedule An Introductory Call",
			            size: "tiny",
			            icon: "calendar alternate outline",
			          }}
			        >
			          <RequestMoreInfo />
			        </FormWrapper>
						
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/marketing-qualified-lead-scoring.webp" className="rounded-image" alt="Marketing Qualified Lead (MQL) Scoring - Websuasion" />
	          </div>
	        }
	        textRight={
			      <div>
							<h2>Identifying and Scoring Marketing Qualified Leads</h2>

							<p>
								Lead scoring assigns values, often numerical points, to each lead generated for the business. This scoring helps prioritize leads, respond to them appropriately, and increase conversion rates.
							</p>
						
							<p>Criteria for MQL Designation:</p>
						
							<ol>
								<li>Demographic and firmographic factors (e.g., industry, company size, job title)</li>
								<li>Behavioral qualification information (e.g., website interactions, content downloads)</li>
								<li>Engagement with marketing efforts (e.g., email opens, social media interactions)</li>
							</ol>
						
							<p>To effectively identify MQLs, consider:</p>
						
							<ol>
								<li>Examining buyer journeys and existing customer behaviors</li>
								<li>Analyzing historical data to identify patterns</li>
								<li>Getting customer feedback to understand their decision-making process</li>
								<li>Identifying trends in successful conversions</li>
							</ol>

							<p>
								A customized lead system will allow you to refine your MQL criteria, tracking, and scoring as you identify what's working. Your system can integrate with multiple existing SaaS and cloud solutions that provide API access. Choose the best solutions for your business, and then automate and connect the systems with a <a href="/marketing-technology/custom-client-portal-software/">centralized custom portal</a>. 
							</p>
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Inbound Sales Methodology</h2>

							<p>
								The Inbound Sales Methodology consists of awareness, consideration, and decision phases. MQLs typically fall into the consideration phase, where prospects actively evaluate solutions to their problems.
							</p>
								
							<h3>The Transition from MQL to SQL</h3>
						
							<p>
								The difference between MQLs and SQLs is the perceived willingness to purchase. While MQLs are curious, SQLs are actively considering a purchase. Factors influencing this transition include lead behavior and likelihood to buy, often assessed using the BANT (Budget, Authority, Needs, Timeline) system.
							</p>
								
							<h3>Importance of Proper Lead Categorization</h3>
						
							<p>
								Correctly categorizing leads ensures that your sales team focuses on the most promising prospects, improving efficiency and conversion rates.
							</p>
														
			        <FormWrapper
			          modal
			          buttonProps={{
			            content: "Schedule An Introductory Call",
			            size: "tiny",
			            icon: "calendar alternate outline",
			          }}
			        >
			          <RequestMoreInfo />
			        </FormWrapper>
						
	          </div>
	        }
	      />
		</PageWrapper>
		<PageWrapper color="white">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/marketing-qualified-lead-sales.webp" className="rounded-image" alt="MQLs and Sales - Websuasion" />
          </div>
        }
        textRight={
		      <div>
						<h2>Collaboration Between Marketing and Sales Teams</h2>

						<p>
							Developing and aligning a shared MQL definition with qualification criteria is crucial for seamless lead handoff.
						</p>

						<h2>Using Buyer Personas as a Starting Point</h2>

						<p>
							Ideal customer personas can provide insights into the characteristics and behaviors of your ideal customers.
						</p>

						<h2>Forecasting MQL Generation Capabilities</h2>

						<p>
							Understanding your ability to generate MQLs helps you set realistic goals and allocate resources effectively.
						</p>

						<h2>Regularly Revisiting and Updating MQL Definitions</h2>

						<p>
							As your business and market evolve, so should your MQL criteria. Regular reviews ensure your definition remains relevant and accurate.
						</p>
					
		        <FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
							
		      </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<h2>Common Misconceptions about MQLs</h2>
					
						<p>
							It's important to dispel some common myths about MQLs:
						</p>
							
						<ol>
							<li>MQLs are not guaranteed sales. While they're more likely to convert than general leads, they still require nurturing.</li>
							<li>MQLs are distinct from regular leads. They've shown active interest, not just passive observation.</li>
							<li>Not all interest equates to an MQL. Defining the criteria for what constitutes an MQL for your business is essential.</li>
						</ol>

						<p>
							Proper MQL management offers numerous benefits:
						</p>

						<ol>
							<li>Improved efficiency for sales teams</li>
							<li>More meaningful conversations with prospects</li>
							<li>Better alignment between marketing and sales</li>
							<li>Valuable insights into the lead conversion process</li>
						</ol>
							
						<h2>Incorporating MQLs into the Sales Process</h2>

						<p>
							To effectively leverage MQLs:
						</p>
							
						<ol>
							<li>Prepare using information gathered during the MQL phase</li>
							<li>Implement targeted nurturing strategies</li>
							<li>Develop a smooth process for transitioning MQLs to the sales team</li>
						</ol>
						
						<h2>Measuring MQL Success</h2>
							
						<p>
							To ensure your <a href="/content-marketing-strategy/">MQL strategy</a> is effective:
						</p>
							
						<ol>
							<li>Define and keep tabs on key performance indicators (KPIs) for MQLs</li>
							<li>Analyze MQL to SQL conversion rates</li>
							<li>Continuously improve your MQL criteria and processes based on data and feedback</li>
						</ol>
							
						<p>
							Marketing Qualified Leads bridge the gap between marketing efforts and sales success. Businesses can significantly improve their lead nurturing and conversion processes by understanding MQLs, how to identify them, and how to manage them effectively.
						</p>
							
						<p>
							Your strategies for identifying and managing MQLs should evolve. Keep up with best practices and emerging trends in lead qualification to maintain a competitive edge in your industry.
						</p>

          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<h2>Frequently Asked Questions About Marketing Qualified Leads</h2>
					
					  <details>
					    <summary>What is a MQL (Marketing Qualified Lead) and a SQL (Sales Qualified Lead)?</summary>
					    <p>
								An MQL has shown interest in your products or services through your marketing efforts, but they have yet to talk to your sales team. An SQL has been vetted by a sales development representative (SDR)
					and is ready to speak to a sales representative.
							</p>
					  </details>
					
					  <details>
					    <summary>What is the difference between a MQL and a prospect?</summary>
					    <p>
								A prospect can be any potential customer. A marketing-qualified lead is a prospect who has demonstrated specific - and preferably trackable -interest in your product or service by engaging with your marketing content.
							</p>
					  </details>

					  <details>
					    <summary>What are examples of marketing-qualified leads?</summary>
					    <p>Usually, a marketing-qualified lead has clicked on one of your calls to action. Examples include:</p>
							<ol>
								<li>Visiting your product or service page multiple times.</li>
								<li>Downloading trial software or ebooks.</li>
								<li>Using demos.</li>
								<li>Filling out forms.</li>
								<li>Subscribing to newsletters.</li>
								<li>Adding items to carts.</li>
								<li>Requesting more information.</li>
								<li>Their actions indicate a strong interest in your product or service.</li>
							</ol>
					  </details>

					  <details>
					    <summary>How do you get marketing-qualified leads?</summary>
					    <p>
								To get MQLs, start by creating valuable content optimized for conversion. Include lead magnets in your calls to action and regularly measure your analytics, making adjustments when those calls to action are underperforming. 
							</p>
					  </details>

					  <details>
					    <summary>What determines a qualified lead?</summary>
					    <p>
								You can consider a lead "qualified" when they check off the requirements your marketing and sales teams have identified as common traits for a prospect ready to buy.
							</p>
					  </details>
						
					  <details>
					    <summary>What are the five requirements for a lead to be considered qualified?</summary>
							<ol>
								<li><strong>Interest:</strong> Prospect shows genuine enthusiasm for your offering.</li>
								<li><strong>Need:</strong> Clear alignment between the prospect's requirements and your solution.</li>
								<li><strong>Budget:</strong> Prospect has the financial capacity to make the purchase.</li>
								<li><strong>Urgency:</strong> Prospect is prepared to take action immediately.</li>
								<li><strong>Authority:</strong> Prospect possesses decision-making power or significant influence in the buying process.</li>
							</ol>
					  </details>
					
					  <details>
					    <summary>How should I track my marketing-qualified leads?</summary>
					    <p>
								Tracking MQLs can be challenging. Monitor your website traffic, comparing each month to previous years to adjust your expectations for seasonality. Track the website visitors who convert to leads and your leads who convert to customers. Measure engagement metrics on social sites and correlate them with referral traffic.
							</p>
					  </details>

					  <details>
					    <summary>How should I qualify my leads?</summary>
					    <p>
								Create a system to score each new lead based on traits that align with your ideal customer profile. You can also analyze their engagement with your content to determine where they are in the customer's journey. This research can help you to follow up with the right messaging.
							</p>
					  </details>
					
						<p>
							Ready to integrate <a href="/marketing-technology/">marketing technology</a> into your lead system? Contact Websuasion today for a free consultation and discover how our <a href="/marketing-technology/">MarTech soilutions</a> and <a href="/fractional-marketing-team/">team of experienced specialists</a> can capture your ideal cutomers.
						</p>
          </div>
        }
      />
		</PageWrapper>
    <PageWrapper color="white">				
			<TextBlock
        textPadded
        textLeft={
          <div>
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/watch?v=-YTR647HTBM'
			          width='100%'
			          height='100%'
			        />
						</div>
          </div>
        }
        textRight={
          <div>
            <h4>Let's Talk!</h4>
            <p>Websuasion combines data analytics, cutting-edge tech, and expert teams. "Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <RequestMoreInfo />
          </div>
        }
      />
		</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage